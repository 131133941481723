.theme-color-primary {
  color: var(--theme-primary) !important;
}

.theme-bg-primary {
  background-color: var(--theme-primary) !important;
}

.bg-theme-info-primary {
  background-color: var(--primary-info);
}

.bg-theme-info-secondary {
  background-color: var(--secondary-info) !important;
}

.bg-theme-info-warning {
  background-color: rgb(211, 47, 47);
  color: rgb(255, 255, 255);
}

.themed-text-color {
  color: var(--text-color) !important;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--theme-primary);
}
