.masonry-grid {
  column-count: 2;
  column-gap: 16px;
}

.masonry-item {
  display: inline-block;
  width: 100%;
  margin-bottom: 16px;
}

@media screen and (max-width: 450px) {
  .masonry-grid {
    column-count: 1;
  }
}
