.app-button {
  position: relative;

  .btn.primary {
    color: var(--btn-primary-color);
    background-color: var(--btn-primary);
    border-color: var(--btn-primary-border);
  }

  .secondary {
    color: var(--white);
    background-color: var(--theme-primary);
    border-color: var(--white);

    &:active {
      color: var(--white);
      background-color: var(--theme-primary);
      border-color: var(--white);
    }
  }

  .spinner-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button-container {
    display: inline-block;
    position: relative;
  }

  .btn {
    text-align: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    min-height: 44px;
    min-width: 44px;
    padding: 10px 32px 10px 32px;
    border: 2px solid;
    margin: 0px;
    border-radius: 500px;
    outline: none;
    transition: all 0.2s ease-in-out 0s;
    text-decoration: none;
    color: var(--white);
    background-color: var(--btn);
    border-color: var(--primary-border);
  }

  .inactive {
    opacity: 0.3;
  }
  @media screen and (min-width: 480px) {
    .btn {
      padding: 10px 45px 10px 45px !important;
    }
  }

  .matching-bg.btn.primary {
    border-color: var(--white);
  }

  .matching-bg.btn.secondary {
    border-color: var(--theme-primary);
  }

  .tooltip-themed {
    background-color: var(--secondary-info);
    color: var(--text-color) !important;
    font-size: 15px;
    border: 1px solid;
    margin-bottom: 5px !important;
  }
}
