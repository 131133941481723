$themes: (
  vw: (
    "theme-primary": #001e50,
    "primary-border": #ffffff,
    "primary-outline": #c00,
    "primary-info": #e6f7fe,
    "text-link": #007bff,
    "text-link-hover": #001e50,
    "secondary-info": #f8f8f8,
    "text-color": #000000,
    "btn": #001e50,
    "bs-btn-active-color": #ffffff,
    "bs-btn-active-bg": #001e50,
    "bs-btn-active-border-color": #ffffff,
    "btn-primary": #f8f8f8,
    "btn-primary-color": #000000,
    "btn-primary-border": #001e50,
    "font-family-base": (
      "VW text",
      sans-serif
    )
  ),
  audi: (
    "theme-primary": #000000,
    "primary-border": #ffffff,
    "primary-outline": #c00,
    "primary-info": #d1dae24d,
    "text-link": #007bff,
    "text-link-hover": #001e50,
    "secondary-info": #f8f8f8,
    "text-color": #000000,
    "btn": #000000,
    "bs-btn-active-color": #ffffff,
    "bs-btn-active-bg": #000000,
    "bs-btn-active-border-color": #ffffff,
    "btn-primary": #f8f8f8,
    "btn-primary-color": #000000,
    "btn-primary-border": #000000,
    "font-family-base": (
      "Audi Type Screen",
      sans-serif
    )
  ),
  skoda: (
    "theme-primary": #0e3a2f,
    "primary-border": #ffffff,
    "primary-outline": #c00,
    "primary-info": #d1dae24d,
    "text-link": #0e3a2f,
    "text-link-hover": #161718,
    "secondary-info": #f8f8f8,
    "text-color": #000000,
    "btn": #0e3a2f,
    "bs-btn-active-color": #ffffff,
    "bs-btn-active-bg": #0e3a2f,
    "bs-btn-active-border-color": #ffffff,
    "btn-primary": #78faae,
    "btn-primary-color": #000000,
    "btn-primary-border": #78faae,
    "font-family-base": (
      "SKODA Next",
      sans-serif
    )
  ),
  seat: (
    "theme-primary": #ea5d1a,
    "primary-border": #ffffff,
    "primary-outline": #c00,
    "primary-info": #d1dae24d,
    "text-link": #007bff,
    "text-link-hover": #001e50,
    "secondary-info": #f8f8f8,
    "text-color": #000000,
    "btn": #ea5d1a,
    "bs-btn-active-color": #ffffff,
    "bs-btn-active-bg": #ea5d1a,
    "bs-btn-active-border-color": #ffffff,
    "btn-primary": #f8f8f8,
    "btn-primary-color": #ea5d1a,
    "btn-primary-border": #ea5d1a,
    "font-family-base": (
      "SeatBcn-Web",
      sans-serif
    )
  ),
  cupra: (
    "theme-primary": #95572b,
    "primary-border": #003e51,
    "primary-outline": #c00,
    "primary-info": #d1dae24d,
    "text-link": #007bff,
    "text-link-hover": #001e50,
    "secondary-info": #f8f8f8,
    "text-color": #000000,
    "btn": #95572b,
    "bs-btn-active-color": #ffffff,
    "bs-btn-active-bg": #95572b,
    "bs-btn-active-border-color": #ffffff,
    "btn-primary": #f8f8f8,
    "btn-primary-color": #95572b,
    "btn-primary-border": #95572b,
    "font-family-base": (
      "Cupra",
      sans-serif
    )
  ),
  porsche: (
    "theme-primary": #1b1d1f,
    "primary-border": #ffffff,
    "primary-outline": green,
    "primary-info": #d1dae24d,
    "text-link": #007bff,
    "text-link-hover": #001e50,
    "secondary-info": #f8f8f8,
    "text-color": #000000,
    "btn": #1b1d1f,
    "bs-btn-active-color": #ffffff,
    "bs-btn-active-bg": #1b1d1f,
    "bs-btn-active-border-color": #ffffff,
    "btn-primary": #f8f8f8,
    "btn-primary-color": #1b1d1f,
    "btn-primary-border": #1b1d1f,
    "font-family-base": (
      "PorscheNextTT",
      sans-serif
    )
  ),
  default: (
    "theme-primary": #234769,
    "primary-border": #ffffff,
    "primary-outline": #c00,
    "primary-info": #d1dae24d,
    "text-link": #007bff,
    "text-link-hover": #001e50,
    "secondary-info": #f8f8f8,
    "text-color": #000000,
    "btn": #234769,
    "bs-btn-active-color": #ffffff,
    "bs-btn-active-bg": #234769,
    "bs-btn-active-border-color": #ffffff,
    "btn-primary": #f8f8f8,
    "btn-primary-color": #000000,
    "btn-primary-border": #234769,
    "font-family-base": (
      "VW text",
      sans-serif
    )
  )
);
@each $name, $map in $themes {
  .app.#{$name} {
    @each $key, $value in $map {
      --#{$key}: #{$value};
    }
  }
}
